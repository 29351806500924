import React, { useEffect } from 'react'
import { UStoreProvider } from '@ustore/core'

const Slot = ({ name }) => {
    const [widgetComponents, setWidgetComponents] = React.useState([]);

    useEffect(() => {
        (async () => {
            window.xmpie_uStore_widgets.instances.forEach((widget) => {
                if (widget.location === name) {
                    const WidgetComponent = window[widget.name].default;
                    const widgetConfig = Object.keys(window.uStoreWidgetsConfiguration).length &&
                        window.uStoreWidgetsConfiguration[widget.id]
                    ? atob(window.uStoreWidgetsConfiguration[widget.id]).toString()
                    : null;
                    setWidgetComponents((widgetComponents) => [...widgetComponents, React.createElement(WidgetComponent, { key: widget.name, uStoreProvider: UStoreProvider, config: widgetConfig })]);
                }
            })
        })();
    }, [name]);
    return widgetComponents;
};

export default Slot;